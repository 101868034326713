import { useMemo } from 'react'

import { useExperiment } from '@src/experiment/experimentStore'
import { useStepRouter } from '@src/hooks/useStepRouter'
import { type Anchor } from '@src/react-utils/bottom-sheet/types'

import { getMiddleOffset } from './anchor'

export function useHomeBottomSheetMiddleAnchorOffsetExperiment(
  anchors: Anchor[]
) {
  const { stepType } = useStepRouter()
  const experiment = useExperiment('bottom_sheet_height')

  const experimentAnchors = useMemo(() => {
    if (stepType !== 'home') {
      return anchors
    }

    return experiment
      ? anchors.map((anchor) => {
          if (anchor.id === 'middle') {
            return {
              ...anchor,
              ...getExperimentAnchor(experiment.assignedGroupName),
            }
          }

          return anchor
        })
      : anchors

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepType, experiment?.assignedGroupName])

  return { experimentAnchors }
}

function getExperimentAnchor(assignedGroupName: string) {
  switch (assignedGroupName) {
    case 'treatment2':
      return {
        offset: 194,
      }
    default:
      return {
        offset: getMiddleOffset(),
      }
  }
}
