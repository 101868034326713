/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { LocalProfilePreviewLocalProfilePreviewNotDisplayableReason } from './LocalProfilePreviewLocalProfilePreviewNotDisplayableReason';
import {
    LocalProfilePreviewLocalProfilePreviewNotDisplayableReasonFromJSON,
    LocalProfilePreviewLocalProfilePreviewNotDisplayableReasonFromJSONTyped,
    LocalProfilePreviewLocalProfilePreviewNotDisplayableReasonToJSON,
} from './LocalProfilePreviewLocalProfilePreviewNotDisplayableReason';
import type { LocalProfilePreviewPhoneCall } from './LocalProfilePreviewPhoneCall';
import {
    LocalProfilePreviewPhoneCallFromJSON,
    LocalProfilePreviewPhoneCallFromJSONTyped,
    LocalProfilePreviewPhoneCallToJSON,
} from './LocalProfilePreviewPhoneCall';
import type { LocalProfilePreviewReview } from './LocalProfilePreviewReview';
import {
    LocalProfilePreviewReviewFromJSON,
    LocalProfilePreviewReviewFromJSONTyped,
    LocalProfilePreviewReviewToJSON,
} from './LocalProfilePreviewReview';
import type { TypeLatLng } from './TypeLatLng';
import {
    TypeLatLngFromJSON,
    TypeLatLngFromJSONTyped,
    TypeLatLngToJSON,
} from './TypeLatLng';

/**
 * 
 * @export
 * @interface V1LocalProfilePreview
 */
export interface V1LocalProfilePreview {
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfilePreview
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfilePreview
     */
    name: string;
    /**
     * 로컬프로필 카테고리 이름 지금은 LP Category는 optional이라 빈 문자열로 내려줄 수 있음.
     * @type {string}
     * @memberof V1LocalProfilePreview
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfilePreview
     */
    roadAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfilePreview
     */
    landAddress?: string;
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1LocalProfilePreview
     */
    latlng?: TypeLatLng;
    /**
     * 
     * @type {LocalProfilePreviewLocalProfilePreviewNotDisplayableReason}
     * @memberof V1LocalProfilePreview
     */
    notDisplayableReason?: LocalProfilePreviewLocalProfilePreviewNotDisplayableReason;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfilePreview
     */
    region3Name: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfilePreview
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfilePreview
     */
    previewImageUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof V1LocalProfilePreview
     */
    reviewCount: number;
    /**
     * 
     * @type {number}
     * @memberof V1LocalProfilePreview
     */
    followerCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof V1LocalProfilePreview
     */
    hasCoupon: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1LocalProfilePreview
     */
    isReservable: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfilePreview
     */
    localProfileTargetUri: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfilePreview
     */
    chatTargetUri?: string;
    /**
     * 
     * @type {LocalProfilePreviewReview}
     * @memberof V1LocalProfilePreview
     */
    review?: LocalProfilePreviewReview;
    /**
     * 
     * @type {LocalProfilePreviewPhoneCall}
     * @memberof V1LocalProfilePreview
     */
    phoneCall?: LocalProfilePreviewPhoneCall;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfilePreview
     */
    suggestionTargetUri?: string;
    /**
     * 
     * @type {number}
     * @memberof V1LocalProfilePreview
     */
    reviewStarRatingAverage: number;
    /**
     * 
     * @type {boolean}
     * @memberof V1LocalProfilePreview
     */
    businessVerified: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1LocalProfilePreview
     */
    identityVerified: boolean;
    /**
     * 
     * @type {number}
     * @memberof V1LocalProfilePreview
     */
    categoryId: number;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfilePreview
     */
    chatCallbackUrl?: string;
}

/**
 * Check if a given object implements the V1LocalProfilePreview interface.
 */
export function instanceOfV1LocalProfilePreview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "region3Name" in value;
    isInstance = isInstance && "reviewCount" in value;
    isInstance = isInstance && "followerCount" in value;
    isInstance = isInstance && "hasCoupon" in value;
    isInstance = isInstance && "isReservable" in value;
    isInstance = isInstance && "localProfileTargetUri" in value;
    isInstance = isInstance && "reviewStarRatingAverage" in value;
    isInstance = isInstance && "businessVerified" in value;
    isInstance = isInstance && "identityVerified" in value;
    isInstance = isInstance && "categoryId" in value;

    return isInstance;
}

export function V1LocalProfilePreviewFromJSON(json: any): V1LocalProfilePreview {
    return V1LocalProfilePreviewFromJSONTyped(json, false);
}

export function V1LocalProfilePreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1LocalProfilePreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'categoryName': !exists(json, 'category_name') ? undefined : json['category_name'],
        'roadAddress': !exists(json, 'road_address') ? undefined : json['road_address'],
        'landAddress': !exists(json, 'land_address') ? undefined : json['land_address'],
        'latlng': !exists(json, 'latlng') ? undefined : TypeLatLngFromJSON(json['latlng']),
        'notDisplayableReason': !exists(json, 'not_displayable_reason') ? undefined : LocalProfilePreviewLocalProfilePreviewNotDisplayableReasonFromJSON(json['not_displayable_reason']),
        'region3Name': json['region3_name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'previewImageUrl': !exists(json, 'preview_image_url') ? undefined : json['preview_image_url'],
        'reviewCount': json['review_count'],
        'followerCount': json['follower_count'],
        'hasCoupon': json['has_coupon'],
        'isReservable': json['is_reservable'],
        'localProfileTargetUri': json['local_profile_target_uri'],
        'chatTargetUri': !exists(json, 'chat_target_uri') ? undefined : json['chat_target_uri'],
        'review': !exists(json, 'review') ? undefined : LocalProfilePreviewReviewFromJSON(json['review']),
        'phoneCall': !exists(json, 'phone_call') ? undefined : LocalProfilePreviewPhoneCallFromJSON(json['phone_call']),
        'suggestionTargetUri': !exists(json, 'suggestion_target_uri') ? undefined : json['suggestion_target_uri'],
        'reviewStarRatingAverage': json['review_star_rating_average'],
        'businessVerified': json['business_verified'],
        'identityVerified': json['identity_verified'],
        'categoryId': json['category_id'],
        'chatCallbackUrl': !exists(json, 'chat_callback_url') ? undefined : json['chat_callback_url'],
    };
}

export function V1LocalProfilePreviewToJSON(value?: V1LocalProfilePreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'category_name': value.categoryName,
        'road_address': value.roadAddress,
        'land_address': value.landAddress,
        'latlng': TypeLatLngToJSON(value.latlng),
        'not_displayable_reason': LocalProfilePreviewLocalProfilePreviewNotDisplayableReasonToJSON(value.notDisplayableReason),
        'region3_name': value.region3Name,
        'description': value.description,
        'preview_image_url': value.previewImageUrl,
        'review_count': value.reviewCount,
        'follower_count': value.followerCount,
        'has_coupon': value.hasCoupon,
        'is_reservable': value.isReservable,
        'local_profile_target_uri': value.localProfileTargetUri,
        'chat_target_uri': value.chatTargetUri,
        'review': LocalProfilePreviewReviewToJSON(value.review),
        'phone_call': LocalProfilePreviewPhoneCallToJSON(value.phoneCall),
        'suggestion_target_uri': value.suggestionTargetUri,
        'review_star_rating_average': value.reviewStarRatingAverage,
        'business_verified': value.businessVerified,
        'identity_verified': value.identityVerified,
        'category_id': value.categoryId,
        'chat_callback_url': value.chatCallbackUrl,
    };
}

