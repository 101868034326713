import { useEffect, useMemo, useState } from 'react'

import { useStepRouter } from '@src/hooks/useStepRouter'

import {
  getBottomSheetAnchors,
  getBottomSheetInitialAnchorId,
  type HeightViewType,
} from './anchor'
import { useHomeBottomSheetMiddleAnchorOffsetExperiment } from './useHomeBottomSheetMiddleAnchorOffsetExperiment'

export function useLandscapeAnchors() {
  const { stepType } = useStepRouter()
  const [heightViewType, setHeightViewType] = useState<HeightViewType>(
    checkHeightViewType()
  )

  const anchors = useMemo(
    () => getBottomSheetAnchors({ heightViewType, stepType }),
    [heightViewType, stepType]
  )
  const initialAnchorId = useMemo(
    () => getBottomSheetInitialAnchorId(heightViewType),
    [heightViewType]
  )

  const { experimentAnchors } =
    useHomeBottomSheetMiddleAnchorOffsetExperiment(anchors)

  useEffect(() => {
    const handleOrientationChange = () => {
      const heightViewType = checkHeightViewType()

      setHeightViewType(heightViewType)
    }

    window.addEventListener('load', handleOrientationChange)
    window.addEventListener('resize', handleOrientationChange)

    return () => {
      window.removeEventListener('load', handleOrientationChange)
      window.removeEventListener('resize', handleOrientationChange)
    }
  }, [])

  return {
    anchors: experimentAnchors,
    initialAnchorId,
  }
}

const BOTTOM_SHEET_LAYOUT_CHANGE_LIMIT = 400
const BOTTOM_SHEET_LAYOUT_CHANGE_LIMIT_MEDIUM = 550

export const checkHeightViewType: () => HeightViewType = () => {
  if (window.innerHeight <= BOTTOM_SHEET_LAYOUT_CHANGE_LIMIT) {
    return 'small'
  }
  if (window.innerHeight <= BOTTOM_SHEET_LAYOUT_CHANGE_LIMIT_MEDIUM) {
    return 'medium'
  }
  return 'large'
}
