/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { Localmapv1LocalProfile } from './Localmapv1LocalProfile';
import {
    Localmapv1LocalProfileFromJSON,
    Localmapv1LocalProfileFromJSONTyped,
    Localmapv1LocalProfileToJSON,
} from './Localmapv1LocalProfile';

/**
 * 
 * @export
 * @interface V1BusinessPost
 */
export interface V1BusinessPost {
    /**
     * 
     * @type {string}
     * @memberof V1BusinessPost
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V1BusinessPost
     */
    title: string;
    /**
     * 
     * @type {Localmapv1LocalProfile}
     * @memberof V1BusinessPost
     */
    localProfile: Localmapv1LocalProfile;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1BusinessPost
     */
    imagesUrls: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof V1BusinessPost
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof V1BusinessPost
     */
    targetUri: string;
    /**
     * 
     * @type {string}
     * @memberof V1BusinessPost
     */
    impressionLoggingUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof V1BusinessPost
     */
    clickLoggingUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof V1BusinessPost
     */
    content: string;
}

/**
 * Check if a given object implements the V1BusinessPost interface.
 */
export function instanceOfV1BusinessPost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "localProfile" in value;
    isInstance = isInstance && "imagesUrls" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "targetUri" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function V1BusinessPostFromJSON(json: any): V1BusinessPost {
    return V1BusinessPostFromJSONTyped(json, false);
}

export function V1BusinessPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1BusinessPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'localProfile': Localmapv1LocalProfileFromJSON(json['local_profile']),
        'imagesUrls': json['images_urls'],
        'createdAt': (new Date(json['created_at'])),
        'targetUri': json['target_uri'],
        'impressionLoggingUrl': !exists(json, 'impression_logging_url') ? undefined : json['impression_logging_url'],
        'clickLoggingUrl': !exists(json, 'click_logging_url') ? undefined : json['click_logging_url'],
        'content': json['content'],
    };
}

export function V1BusinessPostToJSON(value?: V1BusinessPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'local_profile': Localmapv1LocalProfileToJSON(value.localProfile),
        'images_urls': value.imagesUrls,
        'created_at': (value.createdAt.toISOString()),
        'target_uri': value.targetUri,
        'impression_logging_url': value.impressionLoggingUrl,
        'click_logging_url': value.clickLoggingUrl,
        'content': value.content,
    };
}

