/* eslint-disable import/order */
import { useActivity, useStep, useStepActions } from '@stackflow/react'
import { useCallback, useMemo } from 'react'

import { useSearchLogParamActions } from '@src/log/store'

import { parseParams } from './parseParams'
import { FeedStepKeys, type LocalMapStepKeys, type LocalMapSteps } from './type'
import { getPrevStep, setPrevStep } from './prevStep'

export function useStepRouter() {
  const { name, steps, params: urlParams } = useActivity()
  const step = useStep()
  const stepActions = useStepActions(name as never)
  const { updateSearchFunnelId, updateQueryId } = useSearchLogParamActions()

  const stepPush = useCallback(
    ({ step: nextStep, params }: LocalMapSteps) => {
      /**
       * query id 갱신 조건 중: 검색 퍼널에서 벗어난 후, 다시 검색 퍼널로 돌아왔을 때
       * search funnel id는 처음 검색 퍼널 진입 시에만 갱신
       */
      const isEntranceToSearchFunnel =
        step?.params?.step === 'home' && nextStep === 'searchResult'
      if (isEntranceToSearchFunnel) {
        updateQueryId()
        updateSearchFunnelId()
      }

      setPrevStep({
        prevStep: (step?.params?.step ?? 'home') as LocalMapStepKeys,
        method: 'push',
      })

      stepActions.stepPush({
        step: nextStep,
        mapBounds: urlParams.mapBounds, // CommonParams라서 이전 결과와 동일하게 넣어줌
        ...params,
      })
    },
    [
      step?.params?.step,
      stepActions,
      updateQueryId,
      updateSearchFunnelId,
      urlParams.mapBounds,
    ]
  )

  const stepReplace = useCallback(
    ({ step: nextStep, params }: LocalMapSteps) => {
      setPrevStep({
        prevStep: (step?.params?.step ?? 'home') as LocalMapStepKeys,
        method: 'replace',
      })

      stepActions.stepReplace({
        step: nextStep,
        mapBounds: urlParams.mapBounds, // CommonParams라서 이전 결과와 동일하게 넣어줌
        ...params,
      })
    },
    [step?.params?.step, stepActions, urlParams.mapBounds]
  )

  const getValidatedParams = useCallback(
    <T extends LocalMapStepKeys>(stepType: T) => {
      return parseParams<T>(stepType, step?.params)
    },
    [step]
  )

  const stepPop = useCallback(() => {
    setPrevStep({
      prevStep: (step?.params?.step ?? 'home') as LocalMapStepKeys,
      method: 'pop',
    })

    stepActions.stepPop()
  }, [step?.params?.step, stepActions])

  return useMemo(
    () => ({
      stepType: (step?.params?.step || 'home') as LocalMapStepKeys,
      prevStepInfo: getPrevStep(),
      step,
      params: step?.params ?? {}, // TODO. step에 맞게 타입 조정해서 내보낼 수 있어야 함...
      getValidatedParams,
      stepPush,
      stepPop,
      stepReplace,
      steps,
    }),
    [getValidatedParams, step, stepPop, stepPush, stepReplace, steps]
  )
}

export function isFeedStep(stepKey: string): stepKey is FeedStepKeys {
  return FeedStepKeys.includes(stepKey as FeedStepKeys)
}
