import { BoxButton } from '@daangn/sprout-components-button'

import * as css from './RefetchFailedApiPageComponent.css'

interface RefetchFailedApiViewProps {
  message: string
  onRefetchClick: () => void
  buttonLabel?: string
}

export const RefetchFailedApiView = ({
  message,
  onRefetchClick,
  buttonLabel,
}: RefetchFailedApiViewProps) => {
  return (
    <div className={css.container}>
      <div className={css.centerContent}>
        <div className={css.text}>{message}</div>
        <BoxButton variant="secondary" onClick={onRefetchClick}>
          {buttonLabel ? buttonLabel : '다시 불러오기'}
        </BoxButton>
      </div>
    </div>
  )
}
