import { type LocalMapStepKeys } from '@src/hooks/useStepRouter'
import { getSessionStore, setSessionStore } from '@src/store/sessionStore'

const SESSION_STORE_PREV_STEP_KEY = 'homeActivity/prevStep'

interface PrevStepInfo {
  prevStep: LocalMapStepKeys | null
  method: 'push' | 'replace' | 'pop' | null
}

export const getPrevStep: () => PrevStepInfo = () => {
  try {
    const prevStepStr = getSessionStore(SESSION_STORE_PREV_STEP_KEY)

    if (!prevStepStr) {
      return {
        prevStep: null,
        method: null,
      }
    }

    const parsedPrevStep = JSON.parse(prevStepStr)

    if (!parsedPrevStep) {
      return {
        prevStep: null,
        method: null,
      }
    }

    return {
      prevStep: parsedPrevStep.prevStep ?? null,
      method: parsedPrevStep.method ?? null,
    }
  } catch (e) {
    return {
      prevStep: null,
      method: null,
    }
  }
}

export const setPrevStep = (prevStepInfo: PrevStepInfo) => {
  setSessionStore({
    key: SESSION_STORE_PREV_STEP_KEY,
    value: JSON.stringify(prevStepInfo),
  })
}
